$main-grey: #323232;

@font-face {
  font-family: 'Blackberry-Jam';
  src: url('./assets/fonts/blackberry-jam.eot');
  src: url('./assets/fonts/blackberry-jam.ttf') format('truetype'),
       url('./assets/fonts/blackberry-jam.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/blackberry-jam.woff2') format('woff2'),
       url('./assets/fonts/blackberry-jam.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  background-color: black;
  font-family: Blackberry-Jam, Helvetica, sans-serif;
}

// FF ONLY: Remove dashed outline around select elements and set font color on dropdown menu
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 white;
}

// IE/EDGE ONLY: Removes background color of select when focused
select::-ms-value {
  background: none;
}
select {
  option {
    color: white;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0px;
  bottom: 0px;
  position: relative;
}

.svg-icon {
  pointer-events: none;
}

.app {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

header {
  height: 175px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $main-grey;
  flex: 0 0 auto;

  .laur {
    color: white;
    font-family: Helvetica, sans-serif;
    font-size: 60px;
    border: 4px solid white;
    border-radius: 12px;
    margin-left: 40px;
    padding: 4px;
    cursor: pointer;
  }

  .name {
    color: white;
    text-align: center;
    font-size: 48px;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
  }

  .title {
    color: white;
    text-align: center;
    font-size: 42px;
    font-weight: normal;
  }

  .lambtwist {
    color: white;
    float: left;
    margin: 10px;

    &.reverse {
      transform: scaleX(-1);
    }

    svg {
      width: 250px;
      height: auto;
    }
  }

  @media (max-width: 1024px) {
    .lambtwist.reverse {
      margin: 0px;

      svg {
        display: none;
      }
    }
  }

  @media (max-width: 720px) {
    .name {
      font-size: 36px
    }

    .title {
      font-size: 24px
    }

    .lambtwist svg {
      width: 150px;
    }
  }
}

nav {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (min-width: 820px) {
    padding: 0px 40px;
  }

  svg {
    width: 50px;
    height: 50px;
  }

  .nav-btn-container {
    display: none;

    .btn {
      height: 100px;
      width: 100px;
      background-color: $main-grey;
      color: white;
      border-radius: 50px;
      border: 5px solid white;
      cursor: pointer;
      transition: 0.2s ease-out;

      &:hover {
        background-color: white;
        color: black;
      }

      &.selected {
        color: yellow;
        background-color: $main-grey;
        border-color: white;
      }
    }

    @media (min-width: 820px) {
      display: inherit;
    }
  }

  .nav-select-container {
    display: inherit;

    .nav-select-wrapper {
      height: 100px;
      width: 100px;

      select {
        width: 100%;
        appearance: none;
        border: none;
        height: 100%;
        background-color: $main-grey;
        cursor: pointer;
        font-size: 16px; // prevents iOS from zooming in
        color: transparent;
      }
    }

    .nav-select-selection {
      position: absolute;
      width: 100px;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background-color: $main-grey;
      color: yellow;
      pointer-events: none;
      border: 5px solid white;
      border-radius: 50px;
      box-sizing: border-box;

      .svg-icon {
        margin: 10px auto 0px auto;
      }

      .dropdown-svg {
        color: white;
        fill: currentColor;
        stroke: currentColor;
        height: 25px;
        margin: 0px auto;
      }
    }

    @media (min-width: 820px) {
      display: none;
    }
  }
}


main {
  margin: 0px 8px;
  flex: 1 0 auto;

  &.gallery {
    margin: 40px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image-gallery {
    width: 100%;
    margin: 0 auto;
    transition: all 1s ease;

    @media (min-width: 1024px) {
      width: 65%;
    }

    .image-gallery-index {
      top: auto;
      bottom: 0;
      background: none;
      font-family: Helvetica, sans-serif;
      text-shadow: 1px 1px 4px black;
    }
  }

  .home-nav-btn {
    color: red;
    height: 175px;
    width: 80%;
    margin: 50px auto;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1024px) {
      height: 250px;
    }

    &--rings {
      background-image: url('https://www.photos.lauriemacadam.com/1440x960/rings/r1.jpg');
    }

    &--bracelets {
      background-image: url('https://www.photos.lauriemacadam.com/1440x960/bracelets/b1.jpg');
    }

    &--necklaces {
      background-image: url('https://www.photos.lauriemacadam.com/1440x960/necklaces/n1.jpg');
    }

    &--earrings {
      background-image: url('https://www.photos.lauriemacadam.com/1440x960/earrings/e1.jpg');
    }

    &--love {
      background-image: url('https://www.photos.lauriemacadam.com/1440x960/other/o1.jpg');
    }

    .btn-text {
      color: white;
      font-size: 60px;
      font-weight: 700;
      text-shadow: 2px 2px 5px black, -1px -1px 1px black;
    }

    &:hover {
      .btn-text {
        color: yellow;
      }
    }
  }
}


footer {
  color: black;
  background-color: $main-grey;
  position: relative;
  bottom: 0px;
  width: 100%;
  height: 50px;
  font-family: Helvetica, sans-serif;
  flex-shrink: 0;
  text-align: right;

  div {
    position: absolute;
    right: 3px;
    bottom: 2px;

      p {
        margin: 0px;
      }
  }
}
